import React from 'react';
import { Field } from 'formik';

export interface DropdownProps {
    text: string,
    name: string;
    selectedValue: string | number;
    values: any[];
    setFieldValue: (location: string, value: string) => void;
    width?: string;
    callback?: (value: string) => void;
    disabled?: boolean;
    className?: string;
}

const Dropdown = ({
    text,
    name,
    selectedValue,
    values,
    width,
    callback,
    disabled,
    setFieldValue,
    className,
}: DropdownProps): JSX.Element => {
    const filteredValues = values.filter((x) => (x));

    const fontColor = (selectedValue) ? '#262626' : '#70707070';
    return (
        <Field
            className={`input ${className}`}
            as='select'
            name={name}
            style={{ minHeight: 35, width, color: fontColor }}
            disabled={disabled}
            onChange={(callback)
                ? (e: any) => [callback(e.target.value), setFieldValue(name, e.target.value)]
                : (e: any) => setFieldValue(name, e.target.value)}
        >
            <option value='' style={{ color: '#20202026' }}>{text}</option>
            {
                filteredValues.map((x, i) => {
                    return (
                        <option key={x.value} value={x.value}>{x.display}</option>
                    );
                })
            }
        </Field>
    );
};

Dropdown.defaultProps = {
    width: '100%',
    callback: () => { return undefined; },
    disabled: false,
    className: '',
};

export default Dropdown;
