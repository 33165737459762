import submission from 'redux/slices/submission/selectors';

import { RootState } from './store';

const submissionSubmitAttempting = (state: RootState): boolean => submission.submitAttempting(state.submission);
const submissionHasSubmitted = (state: RootState): boolean => submission.hasSubmitted(state.submission);

export default {
    submissionSubmitAttempting,
    submissionHasSubmitted,
};
