import { fork } from 'redux-saga/effects';

import api from 'api';

import submission from './submission';

import { SagaForkReturnType } from './types';

const apiInstance = api;

export default function* root(): SagaForkReturnType {
    yield fork(submission(apiInstance.submission).rootSaga);
}
