import { fork } from 'redux-saga/effects';

import SubmissionGateway from 'api/Submission';

import { RootSagaReturnType } from 'sagas/types';

import watchSubmit from './submit';

export default (api: SubmissionGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchSubmit, api);
    }
    return {
        rootSaga,
    };
};
