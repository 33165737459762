import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';

import CalculatorScreen from 'containers/calculator';

import config from 'config';

const NavRoutes: FunctionComponent = () => {
    return (
        <Router history={history} basename={config.reactBaseRoute}>
            <Routes>
                <Route path='*' element={<CalculatorScreen />} />
            </Routes>
        </Router>
    );
};

export default NavRoutes;
