import React from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';

import Dropdown from 'components/Dropdown';
import NumberInput from 'components/NumberInput';
import TextInput from 'components/TextInput';
import { ISubmitValues } from 'entities/submission';
import Actions from 'redux/Actions';
import Savings from './savings';
import Kwp from './capacity';

const validationSchema = Yup.object().shape({
    phase: Yup.string().required('Phase is required'),
    currentBill: Yup.number().min(1, 'Electricity Bill most be more than 0').required('Electricity Bill is required'),
    salutation: Yup.string().required('Salutation is required'),
    name: Yup.string().required('Name is required'),
    contactNo: Yup.string().required('Contact Number is required'),
    email: Yup.string().email('Email must be valid').required('Email is required'),
    address: Yup.string().required('Address is required'),
    postalCode: Yup.string().required('Postal code is required'),
    state: Yup.string().required('State is required'),
    tnc: Yup.bool().oneOf([true], 'Terms must be checked'),
});

// TODO: handle undefined if only 1 line address
// TODO: postcode number
// TODO: mobile responsiveness

interface CalculatorProps {
    isSubmitting: boolean;
    hasSubmitted: boolean;
    submit(data: ISubmitValues): void;
}
const CalculatorScreen = (props: CalculatorProps): JSX.Element => {
    const { isSubmitting, hasSubmitted, submit } = props;

    const onSubmit = (values: any, actions: FormikHelpers<any>) => {
        submit(values);
    };

    return (
        <Formik
            initialValues={{
                phase: '',
                currentBill: undefined,
                capacity: 0,
                salutation: '',
                name: '',
                contactNo: '',
                email: '',
                address: '',
                postalCode: '',
                state: '',
                more: '',

                kWhPerMonth: 0,
                estSavingsPerYear: 0,
                ROI: 0,
                estSavingsPerMonth: 0,
                estSavingsIn10Years: 0,

                tnc: false,
            }}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
        >
            {propsChild => {
                const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                } = propsChild;

                return (
                    <Form role='form' onSubmit={handleSubmit}>
                        <div className='h-full'>
                            <div className='top-5 max-w-[1050px] absolute m-auto left-0 right-0 bg-white'>
                                <div className='calculator-container flex rounded' style={{ boxShadow: '0px 0px 20px #00000033' }}>
                                    <div className='flex-auto calculator-form rounded-l-lg p-6'>
                                        <h2 className='font-bold'>Solar PV System</h2>
                                        <p>Please fill-up the following details to calculate the potential savings of installing Solar PV System.</p>
                                        <hr className='my-4 color-grey border-t-1' />
                                        <div className='flex flex-row gap-4 electricity-input-container'>
                                            <div className='flex-1'>
                                                <h3 className='font-bold'>Choose Your Phase Type</h3>
                                                <Dropdown
                                                    name='phase'
                                                    selectedValue={values.phase}
                                                    text='Select Type'
                                                    values={[{
                                                        display: 'Single Phase',
                                                        value: 'single',
                                                    }, {
                                                        display: 'Three Phase',
                                                        value: 'three',
                                                    }]}
                                                    width='100%'
                                                    setFieldValue={setFieldValue}
                                                />
                                                {errors.phase && touched.phase ? (
                                                    <small className='text-danger xs'>{errors.phase}</small>
                                                ) : null}
                                            </div>
                                            <div className='flex-1 mb-3'>
                                                <div className='mb-3'>
                                                    <h3 className='font-bold'>Current Electricity Bill</h3>
                                                    <NumberInput
                                                        prefix='RM'
                                                        placeholder='0'
                                                        name='currentBill'
                                                        width='auto'
                                                        setFieldValue={setFieldValue}
                                                    />
                                                    {errors.currentBill && touched.currentBill ? (
                                                        <small className='text-danger xs'>{errors.currentBill}</small>
                                                    ) : null}
                                                </div>

                                                <div className='mb-3'>
                                                    <Kwp
                                                        phase={values.phase}
                                                        capacity={values.capacity}
                                                        currentBill={values.currentBill}
                                                        setFieldValue={setFieldValue}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <Savings
                                            phase={values.phase}
                                            currentBill={values.currentBill}
                                            capacity={values.capacity}
                                            kWhPerMonth={values.kWhPerMonth}
                                            estSavingsPerYear={values.estSavingsPerYear}
                                            ROI={values.ROI}
                                            estSavingsPerMonth={values.estSavingsPerMonth}
                                            estSavingsIn10Years={values.estSavingsIn10Years}
                                            setFieldValue={setFieldValue}
                                            state={values.state}
                                            postalCode={values.postalCode}
                                        />
                                        <p className='text-xs'>
                                            <b>Disclaimer: </b>
                                            <br />
                                            The figures in this solar calculator are indicative only. It is to give an estimate and general guide of the potential savings and benefits of installing solar. The above data may be updated, changed or modified from time to time.
                                        </p>
                                    </div>
                                    <div className='flex-auto user-info-form rounded-lg p-6 font-white background-blue-1'>
                                        <h2 className='font-bold'>Get a Solar PV System Proposal</h2>
                                        <hr className='my-4 color-grey border-t-1' />
                                        <div className='flex flex-col gap-2 mb-4'>
                                            <p>
                                                Salutation*
                                                {errors.salutation && touched.salutation ? (
                                                    <small className='text-danger xs'>{errors.salutation}</small>
                                                ) : null}
                                            </p>
                                            <Dropdown
                                                text='How Should We Address You?'
                                                selectedValue={values.salutation}
                                                name='salutation'
                                                values={[{
                                                    display: 'Mr.',
                                                    value: 'Mr.',
                                                }, {
                                                    display: 'Ms.',
                                                    value: 'Ms.',
                                                }, {
                                                    display: 'Mrs.',
                                                    value: 'Mrs.',
                                                }, {
                                                    display: 'Mdm',
                                                    value: 'Mdm',
                                                }, {
                                                    display: 'Dr.',
                                                    value: 'Dr.',
                                                }, {
                                                    display: 'Dato\'',
                                                    value: 'Dato\'',
                                                }, {
                                                    display: 'Dato Sri',
                                                    value: 'Dato Sri',
                                                }, {
                                                    display: 'Datuk',
                                                    value: 'Datuk',
                                                }, {
                                                    display: 'Tan Sri',
                                                    value: 'Tan Sri',
                                                }, {
                                                    display: 'Tun',
                                                    value: 'Tun',
                                                }]}
                                                setFieldValue={setFieldValue}
                                            />
                                        </div>

                                        <div className='flex flex-col gap-2 mb-4'>
                                            <p>
                                                Name*
                                                {errors.name && touched.name ? (
                                                    <small className='text-danger xs'>{errors.name}</small>
                                                ) : null}
                                            </p>
                                            <TextInput
                                                name='name'
                                                placeholder='Name'
                                                width='100%'
                                            />

                                        </div>

                                        <div className='flex gap-3 user-info-half-container'>
                                            <div className='flex flex-col basis-1/2 gap-2 mb-4 '>
                                                <p>Contact Number*</p>
                                                <TextInput
                                                    name='contactNo'
                                                    placeholder='Phone Number'
                                                    width='100%'
                                                />
                                                {errors.contactNo && touched.contactNo ? (
                                                    <small className='text-danger xs'>{errors.contactNo}</small>
                                                ) : null}
                                            </div>
                                            <div className='flex flex-col basis-1/2 gap-2 mb-4 '>
                                                <p>Email*</p>
                                                <TextInput
                                                    name='email'
                                                    placeholder='Email'
                                                    width='100%'
                                                />
                                                {errors.email && touched.email ? (
                                                    <small className='text-danger xs'>{errors.email}</small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className='flex flex-col gap-2 mb-4'>
                                            <p>
                                                Address*
                                                {errors.address && touched.address ? (
                                                    <small className='text-danger xs'>{errors.address}</small>
                                                ) : null}
                                            </p>
                                            <Field
                                                className='input'
                                                placeholder='Address'
                                                type='textarea'
                                                as='textarea'
                                                name='address'
                                                style={{ width: '100%', height: '70px', color: 'black' }}
                                            />

                                        </div>

                                        <div className='flex gap-3 user-info-half-container'>
                                            <div className='flex flex-col basis-1/2 mb-4'>
                                                <p>Postal code*</p>
                                                <TextInput
                                                    name='postalCode'
                                                    placeholder='52100...'
                                                />
                                                {errors.postalCode && touched.postalCode ? (
                                                    <small className='text-danger xs'>{errors.postalCode}</small>
                                                ) : null}
                                            </div>
                                            <div className='flex flex-col basis-1/2 mb-4'>
                                                <p>State*</p>
                                                <Dropdown
                                                    text='State'
                                                    selectedValue={values.state}
                                                    name='state'
                                                    values={[
                                                        {
                                                            display: 'Johor',
                                                            value: 'Johor',
                                                        }, {
                                                            display: 'Kelantan',
                                                            value: 'Kelantan',
                                                        }, {
                                                            display: 'Kedah',
                                                            value: 'Kedah',
                                                        }, {
                                                            display: 'Perlis',
                                                            value: 'Perlis',
                                                        }, {
                                                            display: 'Melaka',
                                                            value: 'Melaka',
                                                        }, {
                                                            display: 'Negeri Sembilan',
                                                            value: 'Negeri Sembilan',
                                                        }, {
                                                            display: 'Pahang',
                                                            value: 'Pahang',
                                                        }, {
                                                            display: 'Perak',
                                                            value: 'Perak',
                                                        }, {
                                                            display: 'Pulau Pinang',
                                                            value: 'Pulau Pinang',
                                                        }, {
                                                            display: 'Putrajaya',
                                                            value: 'Putrajaya',
                                                        }, {
                                                            display: 'Selangor',
                                                            value: 'Selangor',
                                                        }, {
                                                            display: 'Terengganu',
                                                            value: 'Terengganu',
                                                        }, {
                                                            display: 'Kuala Lumpur',
                                                            value: 'Kuala Lumpur',
                                                        }]}
                                                    setFieldValue={setFieldValue}
                                                />
                                                {errors.state && touched.state ? (
                                                    <small className='text-danger xs'>{errors.state}</small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-2 mb-4'>
                                            <p>{'Is There Anything Else You\'d Like Us To Know?'}</p>
                                            <Field
                                                className='input'
                                                placeholder='Your message...'
                                                type='textarea'
                                                as='textarea'
                                                name='more'
                                                style={{ width: '100%', height: '100px', color: 'black' }}
                                            />
                                            {errors.more && touched.more ? (
                                                <small className='text-danger xs'>{errors.more}</small>
                                            ) : null}
                                        </div>

                                        {errors.tnc && touched.tnc ? (
                                            <small className='text-danger xs'>{errors.tnc}</small>
                                        ) : null}
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label htmlFor='tnc' className='flex text-xs'>
                                            <Field id='tnc' type='checkbox' name='tnc' className='mr-2 rounded' />
                                            I consent for NGC Energy or its representatives to contact me for quotation on Solar PV system and other solutions by telephone and by email for the purpose of general and tailor-made advertising. I may revoke my consent at any time without stating any reasons to NGC Energy.
                                        </label>

                                        {(hasSubmitted) ? (
                                            <div className='py-4 px-2 text-center'>
                                                <p>Thank you for your submission. We will get back to you shortly</p>
                                            </div>
                                        ) : (
                                            <button
                                                type='submit'
                                                disabled={isSubmitting}
                                                className='submit-button btn btn-primary mt-4 background-yellow p-3'
                                            >
                                                SUBMIT
                                            </button>
                                        )}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state: RootState) => ({
    isSubmitting: Selectors.submissionSubmitAttempting(state),
    hasSubmitted: Selectors.submissionHasSubmitted(state),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submit: (param: ISubmitValues) => dispatch(Actions.submitAttempt(param)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CalculatorScreen);
