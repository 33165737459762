import config from 'config';

import SubmissionGateway from './Submission';

const baseUrl = config.baseUrl as string;

const submission = new SubmissionGateway(baseUrl);

export default {
    submission,
};
