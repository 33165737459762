import { push, replace } from 'redux-first-history';
import { CallHistoryMethodAction } from 'redux-first-history/build/es6/actions';

import { store } from 'redux/store';

const navPush = (routeName: string) => {
    store.dispatch(push(routeName));
};

const navReplace = (routeName: string) => {
    store.dispatch(replace(routeName));
};

const navResetToLogin = (): void => navReplace('/login');

const navToHome = (): void => navPush('/');

const navToPdfViewer = (lang:string): void => navPush(`/pdf/${lang}`);

const navToPlayground = (): void => navPush('/playground');

const navToPdf = (language:string):CallHistoryMethodAction => store.dispatch(push('/pdf', { language }));

export default {
    navResetToLogin,

    navToHome,
    navToPdfViewer,
    navToPdf,

    navToPlayground,
};
