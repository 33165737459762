import React from 'react';
import { Field } from 'formik';

export interface TextInputProps {
    placeholder: string,
    name: string;
    width?: string;
    className?: string;
}

const TextInput = ({
    placeholder,
    name,
    width,
    className,
}: TextInputProps): JSX.Element => {
    return (
        <Field
            className={`input ${className}`}
            placeholder={placeholder}
            type='text'
            style={{ width, color: '#262626' }}
            name={name}
        />
    );
};

TextInput.defaultProps = {
    width: '100%',
    className: '',
};

export default TextInput;
