import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { AppDispatch } from 'redux/store';

import Routes from 'navigation/Routes';

import packageJson from '../../package.json';
import config from '../config';

const AppRoot = (): JSX.Element => {
    useEffect(() => {
        console.log(`App Version: ${packageJson.version} ${config.env}`);
    }, []);

    return (
        <Routes />
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
});

export default connect(null, mapDispatchToProps)(AppRoot);
