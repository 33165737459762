import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import SubmissionGateway from 'api/Submission';
import Actions from 'redux/Actions';
import { ISubmitValues } from 'entities/submission';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchSubmit(api: SubmissionGateway):SagaWatcherReturnType {
    yield takeEvery('submission/submitAttempt', handleSubmit, api);
}

function* handleSubmit(api: SubmissionGateway, data: PayloadAction<ISubmitValues>) {
    const res = yield* call([api, api.submit], data.payload);

    if (res.status === GatewayResponseStatus.Error) {
        yield put(Actions.submitFailure(res.message || ''));
    }

    if (res.status === GatewayResponseStatus.Success) {
        yield put(Actions.submitSuccess());
    }
}
