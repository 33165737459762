import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISubmitValues } from 'entities/submission';
import { SubmissionReduxState } from './types';

const initialState: SubmissionReduxState = {
    actions: {
        submit: false,
    },
    hasSubmitted: false,
    error: {
        submit: '',
    },
};

const submissionSlice = createSlice({
    name: 'submission',
    initialState,
    reducers: {
        submitAttempt: (state, action: PayloadAction<ISubmitValues>) => {
            state.actions.submit = true;
            state.hasSubmitted = false;
            state.error.submit = '';
        },
        submitFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.submit = false;
            state.hasSubmitted = false;
            if (action.payload) {
                state.error.submit = action.payload;
            }
        },
        submitSuccess: (state) => {
            state.actions.submit = false;
            state.hasSubmitted = true;
            state.error.submit = '';
        },
    },
});

export type SubmissionState = typeof initialState;

export default {
    actions: submissionSlice.actions,
    reducers: submissionSlice.reducer,
};
