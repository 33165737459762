import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';
import { ISubmitValues } from 'entities/submission';
import ISubmissionGateway from './SubmissionBase';

export default class SubmissionGateway extends ISubmissionGateway {
    async submit(params: ISubmitValues): GatewayResponse<string> {
        const response: ApiResponse<string> = await this.api.post('submission', params);
        return this.process(response);
    }
}
