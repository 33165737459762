import { current } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { RootState } from 'redux/store';

interface IKwpProps {
    phase: string;
    capacity: number;
    currentBill?: number;
    setFieldValue: (name: string, value: any) => void;
}

const Capacity = (props: IKwpProps): JSX.Element => {
    const { phase, capacity, currentBill, setFieldValue } = props;

    useEffect(() => {
        let value = 0;
        if (currentBill) {
            if (phase === 'single') {
                if (currentBill <= 367) {
                    value = 5.22;
                } if (currentBill > 367) {
                    value = 6.96;
                }
            } else if (currentBill <= 367) {
                value = 5.22;
            } else if (currentBill > 367 && currentBill <= 470) {
                value = 6.96;
            } else if (currentBill > 470 && currentBill <= 574) {
                value = 8.70;
            } else if (currentBill > 574 && currentBill <= 713) {
                value = 11.02;
            } else if (currentBill > 713 && currentBill <= 1063) {
                value = 13.92;
            } else if (currentBill > 1063) {
                value = 16.24;
            }
        } else {
            value = 0;
        }

        setFieldValue('capacity', value);
    }, [phase, currentBill]);

    return (
        <>
            <h3 className='font-bold'>Capacity (kWp)</h3>
            <div className='input'>
                {capacity}
            </div>
        </>
    );
};

Capacity.defaultProps = {
    currentBill: 0,
};

const mapStateToProps = (state: RootState) => ({
});

export default connect(mapStateToProps)(Capacity);
