import React from 'react';
import { Field } from 'formik';

export interface NumberInputProps {
    placeholder: string,
    name: string;
    prefix?: string,
    width?: string;
    className?: string;
    setFieldValue: (name: string, value: any) => void;
}

const NumberInput = ({
    prefix,
    placeholder,
    name,
    width,
    className,
    setFieldValue,
}: NumberInputProps): JSX.Element => {
    return (
        <span className={`flex input gap-2 ${className}`}>
            {(prefix) || ''}
            <Field
                placeholder={placeholder}
                type='number'
                name={name}
                style={{ padding: 0, paddingLeft: 0, borderWidth: 0, backgroundColor: 'transparent', width }}
                onWheel={(e: any) => e.target.blur()}
                onChange={(e: any) => {
                    e.preventDefault();
                    const { value } = e.target;
                    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                    if (regex.test(value.toString())) {
                        setFieldValue(name, value);
                    }
                }}
            />
        </span>

    );
};

NumberInput.defaultProps = {
    prefix: '',
    width: '100%',
    className: '',
};

export default NumberInput;
